@media screen and (min-width: 2144px){

    .ntv-container_main{
        display: flex;
        justify-content: center !important;
        padding-top: 50px;
       
    }


    

}



.ntv-container{
    width: 100%;
    background:#e1e6eb;
    
   
    
}

.ntv-container_main{
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;
   
}

.ntv-container_article{
    max-width: 1280px; //1672
    width: 100%;
    
    background: white;
    //margin: 0 auto;
}

.ntv-ad-sidebar-main_container{
   
    max-width: 300px;
    min-width: 200px;
    //margin: 0 40px 0 5px;

}

.ntv-ad-billboard-main_container{
    width: fit-content;
    height: fit-content;
    max-width: 950px;
    max-height: 250px;
    margin: 20px 35px;


}

.ntv-main-billboard_container{
    display: flex;
    justify-content: center;
}

.ntv-ad-nobillboard-main_container{
display: none;
}


.ntv-article-container{
    width: 100%;
  
    display: flex;

}

.ntv-article-main{
  
    height: 100%;
    flex-basis: 68%;
}

.ntv-article-side{
border: 1px solid #e1e6eb;
height: 100%;

width: 100%;
position: relative;
margin-top: 20px;
}

.ntv-article-side-head{
    position: absolute;
    left: 20px;
    top: 20px;
    font-weight: 700;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    color: #46555f;
    font-size: 24px;
    text-transform: uppercase;
}


.ntv-article-box{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}


.ntv-ad-mca-main_container{

    display: none;

   
}

.ntv-ad-inter-main_container{
 display: none;       
}

@media screen and (min-width: 1536px) and (max-width: 1919px){


    .ntv-container {
        width: 100%;
        background: #e1e6eb;
        min-height: 100dvh;
    }

.ntv-container_article{
    max-width: 1036px; //1672
    width: 100%;

    background: white;

}


.ntv-container_main{
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;
   // margin-left: 10%;

   
}


}




@media screen and (max-width: 1790px){

    .ntv-article-main{

       width: 75%;

    }
}



@media screen and (min-width: 1320px) and (max-width: 1536px){


    .ntv-container {
        width: 100%;
        background: #e1e6eb;
        min-height: 100dvh;
    }

.ntv-container_article{
    max-width: 830px; //1036 950
    width: 100%;
 
    background: white;

}


.ntv-container_main{
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;
   // margin-left: 40px;

   
}


}


@media screen and (max-width: 1319px){


    .ntv-container {
        width: 100%;
        background: #e1e6eb;
        min-height: 100dvh;
    }

.ntv-container_article{
    max-width: 750px; 
    width: 100%;
 
    background: white;

}


.ntv-container_main{
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;
    //margin-left: 40px;

   
}

.ntv-article-main{
  
    height: 100%;
 
   width: 100%;
}

.ntv-article-side{
    display: none;
}

}




@media  (max-width: 1100px){
   

    .ntv-container_article{
        max-width: 560px; 
    
    
    }
}


@media  (max-width: 576px){
   
    .ntv-article-main{
  
        flex-basis: 100%;
    }

       .ntv-container_main{
        display: block;
        margin-left: 0px;
        width: 100%;
        padding-top: 0px;
       
    }

    .ntv-ad-sidebar-main_container{
        display: none;
    }
     
    
    .ntv-ad-billboard-main_container{
        display: none;
    }
    

    .ntv-article-side{
        display: none;
    }


    .ntv-article-box{
        margin-top: 20px;
        display: flex;
       flex-direction: column;
       align-items: center;
     
    }
    

    .ntv-ad-mca-main_container{
        position: sticky;
        bottom: -4px;
        width: 100%;
        display: flex;
        transition: 0.5s all;
    
        img{
            height: 100%;
            width: 100%;
        }
        video{
            height: 100%;
            width: 100%;
        }
    }

    .ntv-ad-inter-main_container{
        align-items: center;
        background: #000;
        display: flex;
        height: 100dvh;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 3;
        justify-content: center;
    }
    

    

}


