@media screen and (min-width: 2144px){
    .stern-container_body{

        justify-content: center !important;
    
    }
   
}

@media screen and (min-width: 1920px){

    .stern-ad-sidebar-main_container{
         //width: 0;
     }

}

.stern-container{
    width: 100%;
    background: #f0f0f0;
}


.stern-main-billboard_container{
  
    height: fit-content;
    max-height: 250px;
    margin: 30px auto;
    max-width: 950px;
    display: flex;
    justify-content: center;
    width: fit-content;
}

.stern-container_body{
    width: 100%;
 
    display: flex;
    justify-content: space-evenly;

}

.stern-article_container{
    max-width: 1024px;
    width: 100%;
    
}

.stern-ad-sidebar-main_container{
   // width: 0;
}

@media screen and (min-width: 1536px) and (max-width: 1919px){





}

@media screen and (min-width: 1320px) and (max-width: 1535px){

    /*
    .stern-article_container{
        max-width: 800px;
        width: 100%;
        border: 1px solid;
    }
*/

}


@media screen and (max-width: 1319px){

    .stern-article_container{
        max-width: 720px;
        width: 100%;
        
    }
  

}


@media screen and (width: 1024px){

    .stern-article_container{
        max-width: 700px;
        width: 100%;
        
    }
  

}



@media  (max-width: 576px){
   
 
 .stern-main-billboard_container{
    display: none;
 }   


 .opera_container-dialog{
    background: white;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100dvh;
 opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    

 }

 .opera_container-dialog_container{
    
    height: 30%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
 }


 .opera_container-text{
    font-size: 25px;
    font-weight: bold;
 }

 .opera_container-btn{
    width: 140px;
    background: black;
    color: white;
    font-size: 19px;
    font-weight: bold;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
 }

}

/*
body > iframe {
    display: none;
  }
eslint выключить*/
 
  