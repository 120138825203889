@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


// Footer

.chef-footer{
    width: 100%;
  
   
    padding: 100px 24px;
}


.chef-footer_container{
   
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.chef-footer-icon{
    width: 40px;
    height: 40px;
}

.chef-footer-icons_container{
    display: flex;

    justify-content: space-between;
    width: 30%;
    margin-top: 30px;
}

.chef-footer-text{
    font-weight: 700;
    font-size: 22px;
    font-family: Source Sans Pro,Source Sans Pro-fallback,sans-serif;
    color: #212018;
}

.chef-footer-links_container{
  
    width: 100%;
    border-top: 2px dotted #212018;
    border-bottom: 2px dotted #212018;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 40px 0;
}

.chef-footer-links{

    line-height: 30px;
}

.chef-footer-links-title{
 font-family: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;
 font-size: 15px;
 font-weight: 400;
 text-transform: uppercase;
 color: #212018;
}

.chef-footer-link{
color: #212018;
font-family: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;
font-size: 12px;
font-weight: 400;

}

@media screen and (min-width: 1536px) and (max-width: 1919px){

   
  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

 
    
}



@media screen and (max-width: 1319px){

   
}

@media screen and (max-width: 1280px){
    .chef-footer_container{
        width: 95%;
    }

    .chef-footer-links-title{
        font-size: 11px;
    }

    .chef-footer-link{
        font-size: 10px;
    }
   
}




@media  (max-width: 576px){

 

    .chef-footer-links_container{
  
     flex-direction: column;
    }
    
    .chef-footer{
        padding: 100px 0;
    }

    .chef-footer-icons_container{
        width: 90%;
    }

    .chef-footer-links-title{
        font-size: 14px;
    }

    .chef-footer-link{
        font-size: 13px;
    }

}