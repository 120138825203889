
//bb


.stern-ad-billboard{
    width: 100%;
    height: 100%;

   

}


.stern-ad-billboard-container{

   
  
    width: 100%;
    height: 100%;

    img{
        width: 100%;
        height: 100%;
     }
 
     video{
        width: 100%;
        height: 100%;
     }
}

//hpa

.stern-ad-hpa{
    max-width: 300px;
    margin-left: 10px;
}

.stern-ad-hpa-container{
    height: 100%;

    video{
        height: 100%;
    }
}

//sidebar

.stern-ad-sidebar{
    max-width: 200px;
    margin-left: 10px;
  
    position: sticky;
    top: 0;
}


.stern-ad-sidebar-container{
   
    height: 100%;

   
    video{
      
       height: 100%;
    }
}


@media screen and (max-width: 1600px){

   

}
@media screen and (max-width: 1536px){


    .stern-ad-hpa-container{
        height: 500px;
    }

    .stern-ad-sidebar-container{
   
        height: 500px;
    
       
       
    }


}
@media screen and (max-width: 1400px){


    .stern-ad-hpa-container{
        height: 400px;
    }

    .stern-ad-sidebar-container{
   
        height: 400px;

    }


}


@media screen and (max-width: 1280px){

   
    .stern-ad-sidebar-container{
   
        height: 350px;

    }


}

@media screen and (max-width: 1056px){

    .stern-ad-hpa-container{
        height: 300px;
    }

    .stern-ad-sidebar-container{
   
        height: 280px;
    }
    
}


.InpageInterstitial{
    pointer-events: none;
}


.opera_dialog{
    display: none;
}


@media screen and (max-width: 576px){
    .opera_dialog{
        display: none;
    }
}