.ntv-ad-sidebar{
    width: 100%;
  
    position: sticky;
    top: 0;
}


.ntv-ad-sidebar-container{
   


    width: 100%;
    height: 100%;

    img{
       width: 100%;
       height: 100%;
    }

    video{
       width: 100%;
       height: 100%;
    }
}

// hpa

.ntv-ad-hpa{
   
    max-width: 300px;
    min-width: 200px;

   
}


.ntv-ad-hpa-container{
   
    max-width: 300px;
   min-width: 200px;
  

    img{
        width: 100%;
        height: 100%;
     }
 
  
}

//bb


.ntv-ad-billboard{
    width: 100%;
    height: 100%;
    

}


.ntv-ad-billboard-container{

 
    width: 100%;
    height: 100%;

    img{
        width: 100%;
        height: 100%;
     }
 
     video{
        width: 100%;
        height: 100%;
     }
}

.ntv-ad-medrec{
    display: none;
}



@media screen and (max-width: 1600px){

    .ntv-ad-sidebar-container{
   
      
    
        width: 100%;
  
    
        img{
           width: 100%;
           height: 100%;
        }
    
        video{
           width: 100%;
           height: 100%;
        }
    }


    .ntv-ad-hpa-container{
   
      
        width: 100%;
     
    
        img{
            width: 100%;
            height: 100%;
         }
     
        
    }

}
@media screen and (max-width: 1536px){

    .ntv-ad-sidebar-container{
    
        width: 100%;
        height: 500px !important;
  
    
        img{
           width: 100%;
           height: 100%;
        }
    
        video{
      
           height: 100%;
        }
    }


    .ntv-ad-hpa-container{
   
     
        width: 100%;
        height: 500px !important;
    
        img{
            width: 100%;
            height: 100%;
         }
     
         video{
          
            height: 100%;
         }
         
    }

}



@media screen and (max-width: 1400px) {
    .ntv-ad-hpa-container {
        height: 475px !important;
    }

    .ntv-ad-sidebar-container{
   
        height:475px !important;
    }
}




@media screen and (max-width: 1280px){

    .ntv-ad-sidebar-container{
   
       
          width: 100%;


    
        img{
           width: 100%;
           height: 100%;
        }
    
        video{
           width: 100%;
           height: 100%;
        }
    }


    .ntv-ad-hpa-container{
   
       
        width: 100%;
        height:500px;
    
        img{
            width: 100%;
            height: 100%;
         }
        video{
            width: 100%;
            height: 100%;
         }
     
         
    }

}

@media screen and (max-width: 1100px){

    .ntv-ad-sidebar-container{
   
        
        height: 400px !important;
        width: 100%;
      
    
        img{
           width: 100%;
           height: 100%;
        }
    
        video{
           width: 100%;
           height: 100%;
        }
    }


    .ntv-ad-hpa-container{
   
      
        height: 380px !important;
        width: 100%;
      
    
        img{
            width: 100%;
            height: 100%;
         }
     
        video{
            width: 100%;
            height: 100%;
         }
     
         
    }


    .ntv-ad-mca{
        width: 100%;
        height: 100%;
    }

    .ntv-ad-mca-container{
        width: 100%;
        height: 100%;
    }


    .ntv-ad-interstitial{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
    
    }

    .ntv-ad-interstitial-container{
        width: 100%;
        position: relative;
   

        img{
            width: 100%;
            height: 100%;
         }
     
         video{
            width: 100%;
            height: 100%;
         }
    }

    .ntv-ad-interstitial-closeBtn{
    background: white;
    width: 45px;
    height: 45px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: -15px;
    z-index: 3;
    display: none;
    }


    .ntv-ad-medrec{
        display: flex;
        justify-content: center;
        align-items: center;


    }
    
}



@media screen and (max-width: 576px){

.ntv-ad-interstitial{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  

}


.ntv-ad-interstitial-container{
    width: 100%;
    position: relative;


    img{
        width: 100%;
        height: 100%;
     }
 
     video{
        width: 100%;
        height: 100%;
     }
}

}


.InpageInterstitial{
    pointer-events: none;
}