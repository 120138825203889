
//bb


.stern-ad-billboard{
    width: 100%;
    height: 100%;

   

}


.stern-ad-billboard-container{

   
  
    width: 100%;
    height: 100%;

    img{
        width: 100%;
        height: 100%;
     }
 
     video{
        width: 100%;
        height: 100%;
     }
}

//hpa

.gala-ad-hpa{
    max-width: 300px;
    margin-left: 10px;
}

.gala-ad-hpa-container{
    height: 100%;

    video{
        height: 100%;
    }
}

//sidebar

.gala-ad-sidebar{
    max-width: 200px;
    margin-left: 10px;
  
    position: sticky;
    top: 0;
}


.gala-ad-sidebar-container{
   
    height: 100%;

   
    video{
      
       height: 100%;
    }
}


@media screen and (max-width: 1600px){

   

}
@media screen and (max-width: 1536px){


    .gala-ad-hpa-container{
        height: 500px !important;
    }

    .gala-ad-sidebar-container{
   
        height: 500px !important;
    
       
       
    }


}
@media screen and (max-width: 1400px){


    .gala-ad-hpa-container{
        height: 475px !important;
    }

    .gala-ad-sidebar-container{
   
        height: 500px !important;

    }


}


@media screen and (max-width: 1280px){

   
    .gala-ad-sidebar-container{
   
        height: 450px !important;

    }


}

@media screen and (max-width: 1100px){

    .gala-ad-hpa-container{
        height: 380px !important;
    }

    .gala-ad-sidebar-container{
   
        height: 400px !important;
    }
    
}


.InpageInterstitial{
    pointer-events: none;
}