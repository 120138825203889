
.LinkedHead{
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;

}

.LinkedHeadMobile{
    display: none;
}

.HeadLine{

    margin: auto;
    max-width: 1200px;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
}

.LinkedBlock {
    
    height: inherit;
    display: flex;
    align-items: center;
    width: 40%;
}

.Linkedlogo{

    height: 45px;
    width: 45px;
    
    svg{
        width: 100%;
        height: 100%;
        
    }
}


.LinkedSuche{
   
    max-width: 250px;
    width: 100%;
    height: 35px;
    margin-left: 10px;
    background: #edf3f8;
    color: gray;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 5px;

    img{
        padding-right: 10px;
        padding-left: 10px;
    }
}

.LinkedIcons {
 
    height: inherit;
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: flex-start;
}

.LinkedIcon {
   
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    height: inherit;
    width: 80px;
    justify-content: center;
    text-align: center;
    

    p{
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

.linkAccount{
    width: 30%;
    height: 50%;
    border-radius: 50%;
    
}

.Premium{
    font-size: 11px;
    text-decoration: underline;
    color: #5c3b09;
    height: 45px;
}

.ProductIcon{
    border-left: 1px solid gray;
}



@media  (max-width: 576px){
 
    .LinkedHead{
        display: none;
    }

    .LinkedHeadMobile{
        display: block;
        width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    transition: 0.5s all;
    }

    .HeadLineMobil{

        margin: auto;
        max-width: 1200px;
        width: 100%;
        height: inherit;
        display: flex;
        align-items: center;
    }
    
    .LinkedBlockMobil {
        
        height: inherit;
        display: flex;
        align-items: center;
        width: 90%;
    }
    
    .LinkedlogoMobil{
    
        height: 45px;
        width: 45px;
        
        svg{
            width: 100%;
            height: 100%;
            
        }
    }
    
    
    .LinkedSucheMobil{
       
        max-width: 245px;
        width: 100%;
        height: 35px;
        margin-left: 10px;
        background: #edf3f8;
        color: gray;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        border-radius: 5px;
    
        img{
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    
    .LinkedIconsMobil {
     
        height: inherit;
        display: flex;
        align-items: center;
        width: 10%;
        justify-content: flex-start;
    }
    
    .LinkedIconMobil {
       
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        height: inherit;
        width: 50px;
        justify-content: center;
    }
}