@media screen and (min-width: 2144px){

   
}

.gala-container{
    width: 100%;
    background:  #f8f7f7;
}

.gala-article_container{
    max-width: 1280px;
    width: 100%;
    background: #f8f7f7;
}

.gala-container_body{
    width: 100%;
 
    display: flex;
    justify-content: center;

}
 

@media screen and (min-width: 1920px){



  

   
   
}



@media screen and (min-width: 1536px) and (max-width: 1919px){


    .gala-container_body{
     
     
        justify-content: space-evenly;
    
    }
    


}

@media screen and (min-width: 1320px) and (max-width: 1536px){


    
}


@media screen and (max-width: 1319px){

    .gala-article_container{
        max-width: 750px;
    }

    .gala-container_body{
        width: 100%;
     
        display: flex;
        justify-content: space-evenly;
    
    }
  

}




@media  (max-width: 576px){
   

    .gala-article_container{
      padding: 0 0px;
      width: 95%;
    }
     

}
